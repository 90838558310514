// Copyright (C) 2023 Deconve Technology. All rights reserved.
/* eslint-disable @typescript-eslint/camelcase */

import moment from 'moment';

import { MonitoredUnits, MonitoredUnitsPreview } from './types';

export default function getDemoMonitoredUnits(): Promise<MonitoredUnitsPreview> {
  return new Promise((resolve) => {
    const unitId00 = '00000000-0000-0000-0000-000000000000';
    const unitId01 = '00000000-0000-0000-0000-000000000001';
    const unitId02 = '00000000-0000-0000-0000-000000000002';

    const unitsIds: MonitoredUnits[] = [
      {
        unit: {
          id: unitId00,
        },
        number_of_positive_notifications: 1,
        created_at: moment.utc().add(-12, 'hours').toISOString(),
        added: 'auto',
      },
      {
        unit: {
          id: unitId01,
        },
        number_of_positive_notifications: 1,
        created_at: moment.utc().add(-3, 'hours').toISOString(),
        added: 'manual',
      },
      {
        unit: {
          id: unitId02,
        },
        number_of_positive_notifications: 0,
        created_at: undefined,
        added: 'nearby',
      },
    ];

    const output = { total: unitsIds.length, items: unitsIds, has_more: false };

    resolve(output);
  });
}
