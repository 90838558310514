// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { actions } from './actions';
import { UnitsState } from './types';
import { RootState } from '../../types';

export const state: UnitsState = {};

const namespaced = false;

export const units: Module<UnitsState, RootState> = {
  namespaced,
  state,
  actions,
};
