



































import Vue from 'vue';

import { mapGetters } from 'vuex';
import { mdiClose } from '@mdi/js';
import { getRingtoneFileName } from '@/utils/ringtone';
import { types } from '../store/socket/types';

interface Data {
  showNotification: boolean;
  audio?: HTMLAudioElement;
  notificationIds: string[];
  timer?: number;
  fibonacciValues: number[];
  currentIndex: number;
  intervalTimeoutMs: number;
  icons: Record<string, string>;
}

export default Vue.extend({
  name: 'Notifier',
  data(): Data {
    return {
      showNotification: false,
      audio: undefined,
      notificationIds: [],
      timer: undefined,
      fibonacciValues: [1, 1, 2, 3, 5, 8],
      currentIndex: 0,
      intervalTimeoutMs: 1000,
      icons: {
        close: mdiClose,
      },
    };
  },
  computed: {
    ...mapGetters({
      event: 'getSocketEvent',
      isNotificationEnabled: 'isNotificationEnabled',
      myWorkspace: 'workspace/myWorkspace',
    }),
    workspaceRingtone(): string {
      const { ringtone } = this.myWorkspace;
      const ringtoneFileName = getRingtoneFileName(ringtone);

      return `/sounds/${ringtoneFileName}`;
    },
    isMobileVersion(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch: {
    event: 'onNewEvent',
    myWorkspace: 'setAudio',
  },
  beforeDestroy() {
    window.clearInterval(this.timer);
  },
  created() {
    this.setAudio();
  },
  methods: {
    seeNotification() {
      this.$router.push({
        name: 'faceid-notification',
        params: { notificationId: this.notificationIds[0] },
      });
      this.notificationIds.shift();

      if (this.notificationIds.length === 0) {
        this.close();
      }
    },
    close() {
      this.showNotification = false;

      if (this.audio) {
        // Stop audio and reset time
        this.audio.pause();
        this.audio.currentTime = 0;
        window.clearInterval(this.timer);
      }

      this.notificationIds = [];
    },
    addNotification(notificationId: string): void {
      this.notificationIds.push(notificationId);

      if (this.isNotificationEnabled) {
        this.showNotification = true;

        if (this.audio) this.audio.play();
      }
    },
    removeNotification(notificationId: string): void {
      const notificationIndex = this.notificationIds.indexOf(notificationId);
      const notificationExists = notificationIndex !== -1;

      if (notificationExists) {
        this.notificationIds.splice(notificationIndex, 1);

        if (this.notificationIds.length === 0) {
          this.close();
        }
      }
    },
    onNewEvent(): void {
      if (!this.event) return;

      const { event_type: eventType } = this.event;

      if (eventType === types.COM_DECONVE_FACEID_NOTIFICATION_CREATED) {
        const { notification_id: notificationId } = this.event;

        this.addNotification(notificationId);
      } else if (eventType === types.COM_DECONVE_FACEID_NOTIFICATION_MONITORED) {
        const { notification_id: notificationId } = this.event;

        this.removeNotification(notificationId);
      } else if (eventType === types.COM_DECONVE_FACEID_NOTIFICATION_CONFIRMED) {
        const {
          notification_id: notificationId,
          reviewer_workspace: reviewerWorkspaceId,
        } = this.event;

        const wasReviewedByAnExternalReviewer = reviewerWorkspaceId && (
          reviewerWorkspaceId !== this.myWorkspace.id);

        if (wasReviewedByAnExternalReviewer) {
          this.addNotification(notificationId);
        } else {
          this.removeNotification(notificationId);
        }
      } else if (eventType === types.COM_DECONVE_FACEID_NOTIFICATION_UNCONFIRMED) {
        const { notification_id: notificationId } = this.event;

        this.removeNotification(notificationId);
      } else if (eventType === types.COM_DECONVE_FACEID_NOTIFICATION_REVIEWED) {
        const { notification_id: notificationId } = this.event;

        this.removeNotification(notificationId);
      }
    },
    setAudio(): void {
      if (this.timer) window.clearInterval(this.timer);

      this.audio = new Audio(this.workspaceRingtone);

      this.audio.onended = () => {
        this.playAudio();
      };
    },
    playAudio(): void {
      this.timer = window.setTimeout(() => {
        if (this.currentIndex < this.fibonacciValues.length) {
          const nextFib = this.fibonacciValues[this.currentIndex];

          if (this.audio) this.audio.play();

          this.intervalTimeoutMs = nextFib * 1000;

          this.currentIndex = (this.currentIndex + 1) % this.fibonacciValues.length;
        }

        window.clearTimeout(this.timer);
      }, this.intervalTimeoutMs);
    },
  },
});
