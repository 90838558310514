// Copyright (C) 2020 Deconve Technology. All rights reserved.

import pt from 'vuetify/src/locale/pt';
import onboarding from './onboarding';

const vuetify = {
  ...pt,
  carousel: {
    ariaLabel: {
      delimiter: 'Delimitador',
    },
    next: 'Próximo',
    prev: 'Anterior',
  },
};

export default {
  'pt-br': {
    $vuetify: vuetify,
    deconve: {
      yesterday: 'Ontem',
      lastTwoDays: 'Últimos 2 dias',
      lastSevenDays: 'Últimos 7 dias',
      lastFifteenDays: 'Últimos 15 dias',
      lastThirtyDays: 'Últimos 30 dias',
      thisMonth: 'Esse mês',
      nextFiveMinutes: '5 minutos',
      nextFifteenMinutes: '15 minutos',
      nextThirtyMinutes: '30 minutos',
      nextHour: '1 hora',
      nextTwoHours: '2 horas',
      muteNotificationFor: 'Silenciar notificação por:',
      muteNotifications: 'Silenciar notificações',
      reviewNotificationAs: 'Revisar notificação como:',
      disabledNotificationsUntil: 'As notificações ficarão desativadas até {0} para o vídeo {1}.',
      notificationsSilencerAlert:
        'Ao silenciar os alertas dessa pessoa, as notificações serão desativadas por {0}.',
      deleted: 'Deletado',
      positiveNotification: 'Positiva',
      negativeNotification: 'Negativa',
      unreviewedNotification: 'Não revisada',
      reviewSharing: 'Revisar compartilhamento',
      sharedProfile: 'Perfil compartilhado:',
      currentSharingReviewStatus: 'Status atual:',
      lastSharingReviewStatus: 'Status anterior:',
      versions: {
        'v0.62.0': {
          title: 'Versão 0.62.0',
          changes: [
            {
              name: 'Configuração do app',
              description: 'Preparar configurações para o App em Android',
            },
            {
              name: 'Organização da Base Deconve',
              description: 'Atualizar a notificação com o ID da pessoa que foi criada após uma identificação da base compartilhada',
            },
          ],
        },
        'v0.63.0': {
          title: 'Versão 0.63.0',
          changes: [
            {
              name: 'Finalizar ocorrência',
              description: 'Permitir que os usuários marquem uma ocorrência como concluída',
            },
            {
              name: 'Filtrar ocorrências não preenchidas',
              description: 'Permitir que os usuários filtrem ocorrências por status não preenchido',
            },
          ],
        },
      },
      discoverWhatsNew: 'Descubra o que há de novo',
      ringtone: 'Alerta sonoro',
      ringtoneChord: 'Melodia',
      ringtoneJiggle: 'Jiggle',
      ringtoneNotification: 'Notificação',
      unit: 'Unidade',
      selectedUnit: 'Unidade selecionada',
      selectUnit: 'Selecionar unidade',
      selectedWorkspace: 'Workspace selecionado',
      unknownUnit: 'Unidade desconhecida',
      isSamePerson: 'É a mesma pessoa',
      isNotSamePerson: 'Não é a mesma pessoa',
      showFacePreview: 'Mostrar visualização do rosto',
      hideFacePreview: 'Esconder visualização do rosto',
      referenceUnits: 'Unidades de Referência',
      monitoringStartDate: 'Data do ínicio do monitoramento',
      nearbyUnits: 'Unidades Próximas',
      numberOfPositiveNotifications: 'Número de notificações positivas',
      referenceUnitsInfo: 'As unidades de referência são aquelas por onde a pessoa passou.',
      nearbyUnitsInfo: 'As unidades próximas encontram-se dentro de um raio de 150 km em relação às unidades de referência mencionadas acima ou a uma unidade de referência de outro cliente da Deconve.',
      reportFalseNegative: 'Reportar pessoa não identificada',
      detailFalseNegative: 'Reportar erro de identificação',
      personFalseNegatives: 'Ocorrências em que a pessoa não foi identificada',
      personFalseNegativeFileSizeTooBig: 'O arquivo é muito grande para ser adicionado. Máximo permitido é 20 MB',
      personFalseNegativeFileNameTooLong: 'O nome do arquivo é muito grande. Máximo permitido é de 128 caractéres',
      falseNegative: 'Ocorrência em que a pessoa não foi identificada',
      deleteFalseNegative: 'Deletar ocorrência?',
      deleteFalseNegativeMessage: 'Você tem certeza que deseja deletar essa ocorrência?',
      deletedFalseNegativeMessage: 'Ocorrência deleta.',
      messageForNoFalseNegative: 'Essa pessoa não possui ocorrências onde não foi identificada.',
      messageForAddNewFalseNegative: 'A ocorrência foi adicionada.',
      occurrenceStatus: 'Status da ocorrência',
      originialOccurrenceVideo: 'Vídeo original da ocorrência',
      originalVideoWarning: 'O vídeo deve ser o original do sistema DVR. Não pode ser uma gravação da tela',
      videoFileInput: 'Solte o vídeo aqui ou clique para buscar',
      stole: 'Houve furto',
      triedToSteal: 'Tentativa de furto',
      didNotTryToSteal: 'Não houve tentativa de furto',
      falseNegativeReported:
          'ocorrência em que a pessoa não foi identificada | ocorrência em que a pessoa não foi identificada | ocorrências em que a pessoa não foi identificada',
      deconveDatabaseProfile: 'Perfil da base Deconve',
      deconveProfile: 'Esse perfil é proveniente da base Deconve',
      monitorNotification: 'Acompanhar notificação',
      monitored: 'Monitorada',
      occurrence: 'Ocorrência',
      occurrences: 'Ocorrências',
      noOccurrence: 'Nenhuma ocorrência',
      occurrenceNotification: 'Notificação | Notificações',
      occurrenceValue: 'Valor',
      occurrenceValueOfPrevention: 'Valor da inibição',
      occurrenceValueOfPreventionInSign: 'R$ {0}',
      occurrenceValueOfLoss: 'Valor da perda',
      occurrenceValueOfLossInSign: 'R$ {0}',
      thereWereAnArrest: 'Houve prisão',
      policeWereCalled: 'A polícia foi acionada',
      occurrenceNotifications: 'Notificações da ocorrência',
      occurrencePeople: 'Pessoas presentes na ocorrência',
      triedToStealButWasStopped: 'Tentou furtar, mas foi inibido',
      withoutAlteration: 'Sem alteração',
      updateOccurrenceDetails: 'Atualizar detalhes da ocorrência',
      occurrenceEdited: 'Ocorrência editada com sucesso.',
      deleteOccurrenceTitle: 'Deletar ocorrência',
      deleteOccurrenceMessage: 'Você tem certeza?',
      editOccurrenceError: 'Erro ao editar ocorrência.',
      editOccurrenceSuccess: 'A ocorrência foi editada com sucesso!',
      occurrenceInTrashMessage: 'A ocorrência está na lixeira e será deletada permanentemente em {0}',
      occurrenceFileSizeTooBig: 'O arquivo é muito grande para ser adicionado. Máximo permitido é 20 MB',
      occurrenceFiles: 'Arquivos da ocorrência',
      addOccurrenceFiles: 'Adicionar arquivos',
      addOccurrenceNotifications: 'Adicionar notificações',
      noOccurrenceFiles: 'A ocorrência não possui arquivo',
      noOccurrenceNotifications: 'A ocorrência não possui notificação',
      noOccurrencePeople: 'Nenhuma conhecida no seu workspace',
      noOccurrenceStatus: 'Não preenchido',
      onboarding,
      about: 'Sobre',
      actions: 'Ações',
      actives: 'Ativas',
      account: 'Conta',
      manageAccount: 'Gerenciar conta',
      add: 'Adicionar',
      addUnit: 'Adicionar unidade',
      addVideos: 'Adicionar vídeos',
      added: 'Adicionado',
      addedAt: 'Adicionado em',
      addPanels: 'Adicionar painéis',
      ageRange: 'Faixa etária',
      all: 'Todos',
      allFemale: 'Todas',
      allNotifications: 'Todas as notificações',
      analytics: {
        doohAudicenceTitle: 'Audiência OOH',
        peopleFlowTitle: 'Fluxo de Pessoas',
        retailTitle: 'Análitico do Varejo',
        title: 'Analytics',
        vehicleFlowTitle: 'Fluxo de Veículos',
      },
      anyPeriod: 'Qualquer período',
      apiKeys: 'Chaves de API',
      applyToAll: 'Aplicar em todos',
      approved: 'Aprovado',
      apps: {
        doohAudicenceAnalytics: 'Deconve Analytics: Audiência OOH',
        doohAudicenceAnalyticsDescription:
          'Use dados de medição de audiência em tempo real para quantificar o impacto de suas campanhas',
        peopleFlowAnalytics: 'Deconve Analytics: Fluxo de Pessoas',
        peopleFlowAnalyticsDescription:
          'Tome decisões baseadas em dados para otimizar suas estratégias de marketing e aprimorar a experiência do cliente',
        retailAnalytics: 'Deconve Analytics: Análitico do Varejo',
        retailAnalyticsDescription:
          'Identifique o número de visitantes e visitas, gênero (masculino e feminino) e a idade dos seus clientes de forma automática e em conformidade com a LGPD',
        vehicleFlowAnalytics: 'Deconve Analytics: Fluxo de Veículos',
        faceMatch: 'Deconve Face Match',
        faceMatchDescription:
          'Identifique seus clientes para facilitar pagamentos, evitar fraudes e aprimorar a experiência do usuário. E se quiser, tudo isso de forma offline',
        id: 'Deconve ID',
        idDescription:
          'Evite perdas por furto agindo de forma rápida e com precisão. Tudo isso de forma simples, acessível e em com conformidade com a LGPD',
      },
      ascendingOrder: 'Crescente',
      awaitingReview: 'Aguardando revisão',
      by: 'Por',
      bus: 'Ônibus',
      campaigns: 'Campanhas',
      campaignIntervalDate: 'De {0} a {1}',
      cancel: 'Cancelar',
      cancelRegistration: 'Cancelar cadastro',
      car: 'Carro',
      chooseWorkspace: 'Escolha o workspace',
      clearFilters: 'Limpar filtros',
      close: 'Fechar',
      coordinates: 'Coordenadas',
      confirm: 'Confirmar',
      continue: 'Continuar',
      costPerMileImpressions: 'Custo por mil impressões',
      couldNotFetchWorkspace: 'Não foi possível obter as informações do workspace {0}',
      couldNotFetchWorkspaces: 'Não foi possível obter as informações dos workspaces',
      createCampaign: 'Criar campanha',
      created: 'Criada',
      createdAt: 'Criado em',
      createPanel: 'Criar painel',
      crop: 'Cortar',
      custom: 'Personalizado',
      daily: 'Diário',
      date: 'Data',
      delete: 'Deletar',
      deleteCampaignMessage: 'Você tem certeza que deseja deletar a CAMPANHA {n}?',
      deleteCampaignTitle: 'Deletar campanha',
      deletePanelMessage: 'Você tem certeza que deseja deletar a CAMPANHA {n}?',
      deletePanelTitle: 'Deletar painel',
      demoMode: 'Modo de demonstração',
      descendingOrder: 'Decrescente',
      details: 'Detalhes',
      detectedVehicles: 'Veículos detectados',
      done: 'Concluído',
      doohDashboard: {
        agencies: 'Agências',
        audience: 'Audiência',
        audienceByPanel: 'Audiência por painel',
        peopleByVehicles: 'Pessoas por veículos',
        periods: 'Períodos',
        value: 'Valor',
      },
      downloadCsvByDay: 'Baixar CSV por dia',
      downloadCsvByMonth: 'Baixar CSV por mês',
      downloadCsvByVideo: 'Baixar CSV por vídeo',
      downloadCsvByPerson: 'Baixar CSV por pessoa',
      downloadToolInstallerError:
        'Não foi possível obter a URL do instalador. Por favor, entre em contato com nossa equipe de suporte',
      editCampaign: 'Editar campanha',
      editPanel: 'Editar painel',
      edit: 'Editar',
      editUserPermissions: 'Editar permissões do usuário',
      email: 'Email',
      enable: 'Habilitar',
      enableAutoUpdate: 'Habilitar atualização automática da aplicação',
      enableDemoMode: 'Habilitar o modo de demonstração',
      enableImagePreview: 'Habilitar visualização da imagem',
      enableSimilarPersonSearch: 'Habilitar pesquisa por pessoas duplicadas',
      error: {
        unknown: 'Erro desconhecido',
        internalError: 'Desculpe-nos, ocorreu um problema temporário. Por favor, tente novamente mais tarde',
      },
      exportReport: 'Exportar relatório',
      exposureRate: 'Taxa de exposição',
      faceId: 'Face ID',
      falsePositive: 'Falsas',
      female: 'Feminino',
      fileInput: 'Solte suas imagens aqui ou clique para buscar',
      fileUrlError: 'Não foi possível obter o endereço do arquivo',
      filter: 'Filtro',
      filterPeople: 'Filtrar pessoas',
      finishOccurrence: 'Marcar a ocorrência como finalizada',
      filterProfiles: 'Filtrar perfis',
      forbidden: {
        accessDenied: 'Acesso negado!',
        accessDeniedMessage: 'Você não tem permissão para acessar essa página.',
      },
      from: 'De',
      gender: 'Gênero',
      goBack: 'Voltar',
      groups: 'Grupos',
      history: 'Histórico',
      help: 'Ajuda',
      hour: 'Hora',
      hourLabel: '{n} hora | {n} hora | {n} horas',
      hourlyHeatMapTitle: 'Horas quentes',
      idNotFound: 'ID {0} não encontrado',
      impactedPeople: 'Pessoas impactadas',
      impactedVehicles: 'Veículos impactados',
      impactedVehicleRate: '{0} de {1}',
      impactInThePeriod: 'Impacto no período',
      image: 'Imagem',
      imageUnavailable: 'Imagem indisponível',
      imageHighQuality: 'Alta qualidade',
      images: 'Imagens',
      info: 'Informações',
      inputRules: {
        about: 'O campo sobre deve ser menor que {0} caracteres',
        name: {
          limit: 'O nome deve ser menor que 64 caracteres',
          required: 'Nome é obrigatório',
        },
        noSpecialCharacters: 'Caracteres especiais não são permitidos',
        taxpayerIdNumber: {
          format: 'CPF deve ter esse exemplo de formato: XXX.XXX.XXX-XX',
          limit: 'CPF deve ser menor que 15 caracteres',
        },
        value: {
          limit: 'Valor negativo',
          required: 'Valor é obrigatório',
        },
        vehicleLicensePlate: {
          limit: 'A placa do veículo deve ser menor que 15 caracteres',
        },
      },
      inTrash: 'Na lixeira',
      invalidValue: 'Valor inválido',
      isAdmin: 'É admin?',
      joinSuperProfiles: 'Unir super perfis',
      latitude: 'Latitude',
      loading: 'Carregando...',
      logout: 'Sair',
      longitude: 'Longitude',
      lowerValueForIdentification: 'Baixa semelhança. A pessoa não será identificada',
      male: 'Masculino',
      manageUsers: 'Gerenciar usuários',
      monthly: 'Mensal',
      motorbike: 'Moto',
      moveToTrash: 'Mover para a lixeira',
      movedToTrash: 'Movida para lixeira',
      myWorkspaces: 'Meus workspaces',
      name: 'Nome',
      next: 'Próximo',
      newUser: 'Novo usuário',
      newVersionAvailable: 'Nova versão disponível!',
      nextImage: 'Próxima imagem',
      no: 'Não',
      noNotification: 'Nenhuma notificação',
      noPerson: 'Nenhuma pessoa',
      noResult: 'Nenhum resultado',
      noSimilarProfiles: 'Sem perfis similares',
      noSuperProfile: 'Nenhum super perfil',
      noTags: 'Sem tags',
      notFilled: 'Não preenchido',
      noUnit: 'Sem unidade',
      notification: {
        addImageToProfile: 'Adicionar ao perfil',
        alerts: {
          error: 'Erro ao editar notificação.',
          success: 'A notificação foi editada com sucesso!',
        },
        confirm: 'É a mesma pessoa?',
        created:
          'Nenhuma pessoa identificada | Uma pessoa identificada | {n} pessoas identificadas',
        deleteDialog: {
          message: 'Você tem certeza?',
          title: 'Deletar notificação',
        },
        details: 'Detalhes da notificação',
        expireMode: {
          deletedByReviewStatus: 'Deletada porque ela foi revisada como falsa',
          deletedByUser: 'Deletada pelo usuário',
          personDeleted: 'Deletada porque a pessoa foi deletada',
        },
        isSamePersonTypes: {
          negative: 'Negativo',
          positive: 'Positivo',
          unreviewed: 'Não revisado',
        },
        markFace: 'Marcar face',
        notificationInTrashMessage:
          'A notificação está na lixeira e será deletada permanentemente em {0}',
        notSamePerson: 'NÃO',
        profileImages: 'Imagens do perfil',
        samePerson: 'SIM',
        seeProfile: 'Ver perfil',
        unmarkFace: 'Desmarcar face',
        visibleTimeSeconds: 'Na cena por {0} segundos',
      },
      notificationDisabled: 'Notificações desabilitadas',
      notificationEnabled: 'Notificações habilitadas',
      notifications: 'Notificações',
      notifier: {
        close: 'Fechar',
        view: 'Ver',
      },
      notRecognizedInUnit: 'Não reconhecidas na unidade',
      objectCounter: {
        reportByDay: 'Objetos por dia',
        title: 'Contador de Objetos',
      },
      addOccurrenceReports: 'Adicionar boletins de ocorrência',
      occurrenceFinished: 'Ocorrência finalizada',
      occurrenceNotFinished: 'Ocorrência não finalizada',
      occurrenceReport: 'Boletim de Ocorrência',
      occurrenceReports: 'Boletins de Ocorrência',
      ok: 'Ok',
      otherItems: 'nenhum | um outro | +{count} outros',
      previous: 'Anterior',
      pageIterator: '{0} de {1}',
      pageIteratorPeople: '{0} - {1} de {2}',
      pages: 'Páginas:',
      panel: 'Painel',
      panels: 'Painéis',
      people: 'Pessoas',
      peopleOnSuperProfile: '{n} pessoa no super perfil | {n} pessoas no super perfil',
      peopleDetected: 'Pessoas detectadas',
      peopleFlow: 'Fluxo de pessoas',
      permission: {
        editPermissions: 'Editar permissões',
      },
      permissions: 'Permissões',
      person: {
        addedAt: 'Adicionado em {0}',
        alerts: {
          duplicatePersonName: 'Já há uma pessoa com esse nome cadastrada.',
          imageAlreadyAdded: 'A imagem "{0}" já foi adicionada.',
          imagesNumberLimit: 'Número máximo de imagens permitido é {0}.',
          fileAlreadyAdded: 'O arquivo "{0}" já foi adicionado.',
          fileSizeTooBig: 'O arquivo é muito grande para ser adicionado. Máximo permitido é 20 MB',
          fileNameTooLong: 'O nome do arquivo é muito grande. Máximo permitido é de 128 caractéres',
          filesNumberLimit: 'Número máximo de arquivos permitido é {0}.',
          infos: {
            inputFiles:
              'Escolha imagens de diferentes perspectivas, para facilitar a identificação da pessoa.',
            noPersonFaces:
              'Essa pessoa não possui faces cadastradas para serem utilizadas no processo de identificação. Favor verificar a qualidade e a resolução das imagens cadastradas ou se elas possuem faces selecionadas.',
            personFaces:
              'Essas faces são utilizadas no processo de identificação dessa pessoa. Todas as faces tem que ser dela.',
            qualityMeasurement:
              'Use imagens com boa qualidade e faces detectáveis. Apenas as faces são utilizadas para criar o perfil da pessoa usado pela ferramenta de identificação.',
            resolution: {
              good: 'Boa: se maior do que 112 x 112 pixels',
              low: 'Baixa resolução: se entre 64 x 64 e 112 x 112 pixels',
              measurement:
                'Para adicionar uma imagem com face menor que 64 x 64 pixels, delete as faces detectadas.',
              notAccepted: 'Não aceita: se inferior a 64 x 64 pixels',
              title: 'A resolução da face é considerada:',
            },
            selectSamePerson:
              'Selecione as faces relacionadas à mesma pessoa. Caso esteja cadastrando a pessoa A e há faces da pessoa B, selecione apenas faces da pessoa A.',
            selectedWorkspace: 'Você está realizando um cadastro no workspace {n}',
            selectedWorkspaceEdit: 'Você está editando um cadastro no workspace {n}',
            showImageToCompare: 'Mostrar imagem lado a lado',
          },
          noTagsSelected: 'As tags são obrigatórias para cadastrar uma pessoa.',
          notificationStats:
            'Algumas notificações podem ter sido apagadas do sistema e, portanto, não será possível visualizá-las.',
          noUnitSelected: 'Selecione uma unidade ou marque a opção de unidade desconhecida.',
          personAdded: 'Pessoa adicionada com sucesso.',
          personEdited: 'Pessoa editada com sucesso.',
          personEditFailed: 'Não foi possível editar a pessoa.',
          validationImage: {
            lowResolution: 'Remova a face de baixa resolução.',
            noFaceDetected: 'É necessário ao menos uma imagem com face detectável.',
            noFaceSelected: 'Selecione uma face das imagens com face detectada.',
          },
        },
        chooseImage: 'Escolher imagem',
        deleteDialog: {
          message: 'Você tem certeza?',
          title: 'Deletar pessoa',
        },
        deleteFaces: 'Deletar faces',
        detectedFaces: 'Faces detectadas',
        detectFaces: 'Detectar faces',
        editProfile: 'Editar perfil',
        facesOfThisPerson: 'Faces dessa pessoa',
        identifiedPeople: 'Uma pessoa semelhante a esta foi encontrada na base | {n} pessoas semelhantes a esta foram encontradas na base',
        noPersonIdentified:
          'Nenhuma pessoa identificada',
        image: {
          noFaceSelected: 'Selecione uma face',
          quality: {
            good: 'Boa',
            low: 'Baixa',
            regular: 'Regular',
            title: 'Exemplos de qualidade da imagem:',
          },
          resolution: {
            good: 'Resolução boa',
            low: 'Resolução baixa',
            notAccepted: 'Resolução não aceita',
          },
        },
        name: 'Pessoa',
        noDescription: 'Sem descrição.',
        noFacesDetectedInImage: 'Nenhuma face foi detectada na imagem',
        noTrainedModels:
          'Esta pessoa não está sendo monitorada. Verifique se há erros no perfil dela',
        personInTrashMessage:
          'Esse cadastro está na lixeira e será deletado permanentemente em {0}',
        profile: 'Perfil',
        registerPerson: 'Cadastrar pessoa',
        searchByImage: 'Buscar por imagem',
        searchForThisFace: 'Buscar por esta face',
        searchingByImage: 'Buscando por imagem',
        setProfile: 'Definir como perfil',
        similarityLevel: 'Nível de semelhança',
        similarPeople: 'Pessoas semelhantes',
        sortImages: 'Organizar imagens',
        submitDialog: {
          lowQuality:
            'Foi detectada uma face com baixa qualidade que pode comprometer a taxa de acerto do sistema. Deseja continuar?',
          lowResolution:
            'Foi detectada uma face com baixa resolução que pode comprometer a taxa de acerto do sistema. Deseja continuar?',
          lowResolutionAndLowQuality:
            'Foi detectada uma face com baixa resolução e baixa qualidade que pode comprometer a taxa de acerto do sistema. Deseja continuar?',
          lowResolutionAndRegularQuality:
            'Foi detectada uma face com baixa resolução e qualidade regular que pode comprometer a taxa de acerto do sistema. Deseja continuar?',
          regularQuality:
            'Foi detectada uma face com qualidade regular que pode comprometer a taxa de acerto do sistema. Deseja continuar?',
          title: 'Enviar formulário',
        },
      },
      personName: 'Nome',
      playerStopped: 'Player parado',
      playerOffline: 'Player offline',
      previousImage: 'Imagem anterior',
      profileNotFound: 'Perfil não encontrado',
      restored: 'Restaurada',
      reviewed: 'Revisada',
      reviewedMale: 'Revisado',
      reviewStatus: 'Status da revisão',
      refresh: 'Atualizar',
      rejected: 'Rejeitado',
      relatedProfiles: 'Perfis relacionados',
      relatedProfilesNumber: '{n} perfis relacionados | {n} perfil relacionado | {n} perfis relacionados',
      reload: 'Recarregar',
      remove: 'Remover',
      removeReportFile:
        'Deseja remover o arquivo {0}? Essa operação será confirmada após enviar o formulário',
      removeImageFile:
        'Deseja remover a imagem {0}? Essa operação será confirmada após enviar o formulário',
      removeVideoFile:
        'Deseja remover o vídeo {0}? Essa operação será confirmada após enviar o formulário',
      removeFile: 'Remover arquivo',
      report: {
        chartTypes: 'Tipos de gráfico',
        dateInterval: 'Intervalo de data',
        filter: {
          all: 'Todos',
          chart: {
            bar: 'Gráfico de barra',
          },
          negatives: 'Negativos',
          period: {
            byDay: 'Por dia',
            byHour: 'Por hora',
            byMonth: 'Por mês',
          },
          positives: 'Positivos',
          positivesAndNegatives: 'Positivos e negativos',
          total: 'Total',
          unreviewed: 'Não revisados',
        },
        negativeNotifications:
          'notificação negativa | notificação negativa | notificações negativas',
        notificationRegistrationTime: 'Tempo de registro das notificações',
        notificationRegistrationTimeInfo: 'Em segundos',
        notificationsChart: 'Número de notificações',
        notificationStatus: 'Status da notificação',
        periods: 'Períodos',
        personName: 'Nome da pessoa',
        positiveNotifications:
          'Sem notificações positivas | {n} notificação positiva | {n} notificações positivas',
        positiveNotifications2:
          'notificação positiva | notificação positiva | notificações positivas',
        statusTypes: 'Tipos de status',
        unreviewedNotifications:
          'notificação sem revisão | notificação sem revisão | notificações sem revisão',
      },
      reports: 'Relatórios',
      reset: 'Resetar',
      restoreFromTrash: 'Restaurar da lixeira',
      retry: 'Tentar novamente',
      revision: 'Revisão',
      role: 'Função',
      save: 'Salvar',
      scopes: {
        application: 'Aplicações',
        campaigns: 'Campanhas',
        careMonitor: 'Monitor de Cuidado',
        doohAnalytics: 'Analytics: Audiência OOH',
        doohAndVehicleFlowAnalytics: 'Analytics: Audiência OOH e Fluxo de Veículos',
        name: 'Escopos',
        peopleCounter: 'Analytics: Fluxo de Pessoas',
        permission: {
          create: 'Criar',
          delete: 'Deletar',
          read: 'Ler',
          update: 'Atualizar',
        },
        retailAnalytics: 'Retail Analytics',
        type: {
          model: 'Modelo',
          notification: 'Notificação',
          occurrence: 'Ocorrência',
          person: 'Pessoa',
          report: 'Relatório',
          settings: 'Configurações do workspace',
          status: 'Status das aplicações',
          tags: 'Tags',
          users: 'Usuários',
          vehicle: 'Veículo',
        },
        units: 'Unidades',
        vehicleFlowAnalytics: 'Analytics: Fluxo de Veículos',
        vehicleLicensePlateRecognition: 'Reconhecimento de placa de veículos',
        videos: 'Vídeos',
        workspace: 'Workspace',
      },
      search: 'Procurar',
      seeCampaigns: 'Ver campanhas',
      seePanels: 'Ver painéis',
      seePermissions: 'Ver permissões',
      selectDate: 'Selecionar data',
      selectedProfiles: 'Perfis selecionados',
      selectNotifications: 'Selecionar notificações',
      selectNotificationsAlert: 'As notificações adicionadas a esta ocorrência serão automaticamente removidas de outras ocorrências',
      selectProfiles: 'Adicionar perfis',
      sentBy: 'Enviado por',
      // TODO: fix english. setting should be renamed to settings
      setting: {
        apiKey: {
          accessKey: 'Chave de acesso',
          alert: {
            copyAccessKey:
              'Certifique-se de copiar e guardar sua nova chave de acesso agora. Você não irá vê-la novamente.',
          },
          apiKeyPermissions: 'Permissões da chave de API',
          confirmDeletion: {
            message: 'Você tem certeza que deseja deletar essa chave?',
            title: 'Deletar chave de API',
          },
          definePermissions: 'Definir permissões',
          editApiKey: 'Editar chave de API',
          id: 'Id',
          key: 'Chave',
          keyName: 'Nome da chave',
          manageKeys: 'Gerenciar chaves',
          newApiKey: 'Nova chave de API',
          newKey: 'Nova chave',
        },
        apiKeys: 'Chaves de API',
        general: 'Geral',
        generalAlert: {
          maxDays: 'O número máximo de dias é 30',
        },
        generalSettings: 'Configurações gerais',
        maxDaysToDeleteResourceInTrash:
          'Número máximo de dias para deletar os recursos da lixeira permanentemente',
        personTagMandatory: 'Exigir tags no cadastro de pessoas',
        workspace: {
          addLogo: 'Adicionar logo',
          alert: {
            imageSize: 'O tamanho da imagem deve ser inferior à {0}',
            success: 'O workspace foi editado com sucesso!',
          },
          changeLogo: 'Alterar logo',
          invites: 'Convites',
          member: {
            confirmDeletion: {
              message: 'Você tem certeza que deseja deletar o usuário {n} do workspace atual?',
              title: 'Deletar membro',
            },
            editMember: 'Editar membro',
            editMemberPermissions: 'Editar permissões do membro',
          },
          subdomain: 'Subdomínio',
          workspaceSettings: 'Configurações do Workspace',
        },
      },
      settings: 'Configurações',
      sharing: 'Compartilhamento',
      sharingStatus: 'Status de compartilhamento',
      showNotifications: 'Mostrar',
      similarSuperProfiles: 'Super perfis similares',
      sortBy: 'Ordenar por',
      sortOrder: 'Ordem',
      status: 'Status',
      submit: 'Enviar',
      superProfiles: 'Super Perfis',
      tag: {
        addedTags: 'Tags adicionadas',
        addTags: 'Adicionar tags',
        createdTags: 'Tags criadas',
        createTag: 'Criar tag',
        key: 'Chave',
        newTag: 'Nova tag',
        tagColor: 'Cor da tag',
        tagType: 'Tipo de tag',
        type: {
          keyAndValue: 'Chave e valor',
          keyOnly: 'Apenas chave',
          shared: 'Compartilhado',
        },
        value: 'Valor',
      },
      tags: 'Tags',
      tagsUsed: 'Tags utilizadas',
      taxpayerIdNumber: 'CPF',
      today: 'Hoje',
      tools: 'Ferramentas',
      total: 'Total',
      totalByVideo: 'Total por vídeo',
      truck: 'Caminhão',
      truePositive: 'Verdadeiras',
      ubuntunOnlineInstaller: 'Instalador Online para Ubuntu (18.04 ou superior) 64 bits',
      userNotFound: '{0} usuário não encontrado',
      blaBla: 'bar baz',
      updateDate: 'Data de atualização',
      updated: 'Atualizada',
      unitAdded: 'Unidade salva com sucesso',
      unitCreatedMessage:
        'Para poder acessar os dados em nossos servidores, é necessário que essa unidade possua uma chave de API. Deseja criar uma agora?',
      unitEdited: 'Unidade editada com sucesso',
      unitNotFound: 'Unidade {0} não encontrada',
      units: 'Unidades',
      unreviewed: 'Não revisadas',
      until: 'Até',
      userPermissions: 'Permissões do usuário',
      users: 'Usuários',
      vehicleLicensePlate: 'Placa do veículo',
      vehicles: 'Veículos',
      vehicleType: {
        bus: 'Ônibus {0}',
        car: 'Carro {0}',
        motorbike: 'Moto {0}',
        truck: 'Caminhão {0}',
      },
      vehicleTypes: 'Tipos de veículos',
      video: 'Vídeo',
      videos: 'Vídeos',
      videosWithHighestFlow: 'Os {0} com maiores fluxos',
      videosWithLowestFlow: 'Os {0} com menores fluxos',
      viewMore: 'Ver mais',
      visits: 'Visitas',
      visitors: 'Visitantes',
      visitorsPerUnit: 'Visitantes por unidade',
      weekly: 'Semanal',
      whatIsNew: 'O que há de novo',
      windowsOnlineInstaller: 'Instalador Online para Windows (7 ou 10) 64 bits',
      with: 'Com',
      without: 'Sem',
      withSimilarProfiles: 'Com perfis similares',
      workspace: 'Workspace',
      workspaces: 'Workspaces',
      years: 'Anos',
      yes: 'Sim',
      you: 'Você',
    },
  },
};
