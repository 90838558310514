// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';

import { UnitsState, MonitoredUnits, MonitoredUnitsPreview } from './types';
import { RootState } from '../../types';
import getDemoMonitoredUnits from './demo';

export const actions: ActionTree<UnitsState, RootState> = {
  fetchMonitoredUnits({ rootGetters }, personId: string): Promise<MonitoredUnits[]> {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/people/${personId}/actions/monitoring/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      if (rootGetters.isDemoMode) {
        getDemoMonitoredUnits().then((data: MonitoredUnitsPreview) => {
          const { items: unitIds } = data;

          resolve(unitIds);
        });
      } else {
        axios(requestOptions).then((response) => {
          const { data } = response;
          const { items: unitIds } = data;

          resolve(unitIds);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  },
};

export default actions;
