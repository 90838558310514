// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import {
  SuperProfile, SuperProfilesState, SuperProfilesPreviewPage, types,
} from './types';

const kMaxBufferSize = 128;

export const mutations: MutationTree<SuperProfilesState> = {
  [types.GET_FACEID_SUPER_PROFILES_PREVIEW_SUCCESS](state, data: SuperProfilesPreviewPage) {
    state.superProfilesPreviewList.total = data.total;
    state.superProfilesPreviewList.items = data.items;
  },
  [types.GET_FACEID_SUPER_PROFILE_SUCCESS](state, data: SuperProfile) {
    state.superProfile.data = data;
    state.superProfile.isLoading = false;

    const { id: superProfileId } = data;
    const profileIndex = state.superProfiles.findIndex(({ id }) => id === superProfileId);

    if (profileIndex >= 0) {
      state.superProfiles.splice(profileIndex, 1, data);
    } else {
      const numberOfPeople = state.superProfiles.push(data);

      if (numberOfPeople > kMaxBufferSize) {
        state.superProfiles.shift();
      }
    }
  },
  [types.GET_FACEID_SUPER_PROFILE_REQUEST](state) {
    state.superProfile.isLoading = true;
  },
  [types.EDIT_FACEID_SUPER_PROFILE_REQUEST](state) {
    state.superProfile.isLoading = true;
  },
  [types.EDIT_FACEID_SUPER_PROFILE_FAILURE](state, error) {
    state.superProfile.error = error;
    state.superProfile.isLoading = false;
  },
  [types.EDIT_FACEID_SUPER_PROFILE_SUCCESS](state, data) {
    state.superProfile.data = data;
    state.superProfile.isLoading = false;

    const { id: superProfileId } = data;
    const profileIndex = state.superProfiles.findIndex(({ id }) => id === superProfileId);

    if (profileIndex >= 0) {
      state.superProfiles.splice(profileIndex, 1, data);
    } else {
      const numberOfPeople = state.superProfiles.push(data);

      if (numberOfPeople > kMaxBufferSize) {
        state.superProfiles.shift();
      }
    }
  },
  setSuperProfileIteratorSettings(state, data) {
    state.superProfileFiltersSettings = data;
  },
  resetError(state) {
    state.superProfile.error = undefined;
  },
};

export default mutations;
